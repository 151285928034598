import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.css';
import '../select/styles.css';

const { Option } = Select;

export const SearchInput = ({
  placeholder,
  label,
  options,
  value,
  onChange,
  onSearch,
  customOptionRender,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Select
      showSearch
      allowClear
      value={value}
      placeholder={placeholder}
      className="mg-select-custom"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      onChange={onChange}
      notFoundContent={null}
      {...rest}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value} label={t(option.label)}>
          {customOptionRender ? customOptionRender(option) : option?.customOptionTitle || t(option.label)}
        </Option>
      ))}
    </Select>
  );
};
