const getTooltipAndXLine = (chart, id) => {
  const tooltipEl = document.getElementById(`tooltip-${id}`);
  const lineEl = document.getElementById(`x-axis-line-${id}`);
  return { tooltipEl, lineEl };
};

export const externalTooltipHandler = (context, id, isNeedDatasetText, isTooltipXLeftAlign) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const { tooltipEl, lineEl } = getTooltipAndXLine(chart, id);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    if (lineEl) {
      lineEl.style.opacity = 0;
    }
    return;
  }

  // Set Text
  if (tooltip.body) {
    if (tooltip.dataPoints[0].dataset.label === 'ROE modified, % (trend)') {
      tooltip.dataPoints.reverse();
      tooltip.labelColors.reverse();
    }
    const titleLines = tooltip.title || [];
    const bodyLines = isNeedDatasetText
      ? tooltip.dataPoints.map((b) => b.formattedValue + ' ' + b.dataset.label)
      : tooltip.dataPoints.map((b) => b.formattedValue + (b.raw?.valueExtraLabel ? ' ' + b.raw?.valueExtraLabel : ''));

    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      // const text = document.createTextNode(title);

      // th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement('span');
      span.style.background = 'white';
      // span.style.height = '10px';
      // span.style.width = '10px';
      span.style.display = 'inline-block';
      span.style.color = tooltip.labelColors[i].borderColor === '#BDFFBD' ? '#00D100' : tooltip.labelColors[i];
      span.style.textAlign = 'center';
      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;
      tr.style.padding = '0px';

      const td = document.createElement('td');
      td.style.borderWidth = 0;
      td.style.color = colors.borderColor;
      td.style.padding = i === bodyLines.length - 1 ? '6px 12px 6px 12px' : '6px 12px 0 12px';

      const text = document.createTextNode(body);

      span.appendChild(text);
      td.appendChild(span);
      // td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Display, position, and set styles for font
  if (lineEl) {
    lineEl.style.height = chart.height - 80 + 'px';
    lineEl.style.opacity = 1;
    lineEl.style.left = positionX + tooltip.caretX + 'px';
    lineEl.style.top = positionY + 30 + 'px';
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.left = positionX + tooltip.caretX - (isTooltipXLeftAlign && tooltipEl.offsetWidth / 2 + 5) + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY - tooltip.height / 2 + 'px';
};
