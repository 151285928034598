import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ErrorModal } from '../error-modal';
import { appMessage } from '../message';
import { typeMessage } from '../message/constants';

export const ErrorHandling = ({ children }) => {
  const { i18n } = useTranslation();

  /** @type {UseHelper.UseStateType<Errors.RequestError>} */
  const [errorModalProps, setErrorModalProps] = useState({ status: 400, message: 'Error', data: {} });
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const handleError = useCallback(
    (e) => {
      if (e.message === 'canceled') {
        appMessage(typeMessage.ERROR, i18n.t(e.message), e.data);
      } else if (
        e.message.includes('restriction') ||
        e.message.includes('error') ||
        e.message.includes('Wiki') ||
        e.message.includes('tax_regime_exists')
      ) {
        setErrorModalProps(e);
        setErrorModalVisible(true);
      } else {
        appMessage(typeMessage.ERROR, i18n.t(e.message), e.data);
      }
    },
    [i18n]
  );

  const NewChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      handleError
    });
  });

  return (
    <>
      {NewChildren}
      <ErrorModal isVisible={errorModalVisible} setInvisible={() => setErrorModalVisible(false)} e={errorModalProps} />
    </>
  );
};
